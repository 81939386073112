<template>
    <div class="layout-container">
        <v-sidebar ref="sidebar" class="name-left" @toPopover="toPopover"/>
        <el-popover
                trigger="click"
                placement="right"
                width="200"
                v-model="visible"
                class="popover"
        >
            <div class="neirong">
                <div class="md-info">
                    <span style="font-weight: bold">{{dept.deptName}}</span>
                    <span style="color: #999999;font-size: 10px">到期时间: {{dept.expireTime}}</span>
                </div>
                <div class="md-tongzhi" @click="toNotice">
                    <span>消息通知</span>
                    <i class="el-icon-message-solid" style="font-size: 18px"></i>
                </div>
                <div class="md-loginout">
                    <span class="loginoutbtn" @click="loginOut">退出登录</span>
                </div>
            </div>
        </el-popover>
        <router-view class="name-right"></router-view>
        <el-dialog
                :close-on-click-modal="false"
                title="生日提醒"
                :visible.sync="srVisible"
                width="800px"
                :before-close="closeEvent"
        >
            <div>
                <el-table :data="list" height="550px" style="width: 100%" border>
                    <el-table-column prop="name" label="头像" align="center">
                        <template slot-scope="scope">
                            <div style="display:flex;justify-content: center">
                                <img v-if="!scope.row.headPortrait&&scope.row.sex == 0"
                                     src="../../assets/images/nan.png" class="mb-img">
                                <img v-if="!scope.row.headPortrait&&scope.row.sex == 1"
                                     src="../../assets/images/nan.png" class="mb-img">
                                <img v-if="!scope.row.headPortrait&&scope.row.sex == 2" src="../../assets/images/nv.png"
                                     class="mb-img">
                                <img v-if="scope.row.headPortrait" :src="scope.row.headPortrait" class="mb-img">
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="memberName" label="姓名" align="center"/>
                    <el-table-column prop="name" label="性别" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.sex==0">未知</span>
                            <span v-if="scope.row.sex==1">男</span>
                            <span v-if="scope.row.sex==2">女</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="手机" align="center"/>
                    <el-table-column prop="birthdayStr" label="生日" align="center">
                      <template slot-scope="scope">
                        <div >{{scope.row.birthdayStr}}</div>
                        <div >(农历) {{scope.row.ylDate}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="name" label="蛋糕" align="center">
                        <template slot-scope="scope">
                            <img src="./../../assets/images/sr.png" style="height: 38px;width: 38px;">
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import vSidebar from './Sidebar'
    import {getSrtk, getToken, removeSrtk, removeToken, setSrtk} from "../../utils/auth";
    // 获取当前日期
    const today = new Date();
    // 格式化日期为YYYY-MM-DD
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    export default {
        components: {
            vSidebar
        },
        data() {
            return {
                visible: false,
                userId: '',
                dept: {},
                list: [],
                srVisible: false,
            };
        },
        async created() {
            await this.getDept()
            let dateStr = formatDate(today);
            let item = getSrtk()
            if (!item) {
                await this.getBirthdayMembers()
                if (this.list.length > 0) {
                    this.srVisible = true
                    let tk = {
                        userId: this.userId,
                        date: dateStr,
                        status: true,
                    }
                    setSrtk(tk)
                }
            } else {
                let it = JSON.parse(item)
                if (!it.userId || !it.date) {
                    await this.getBirthdayMembers()
                    if (this.list.length > 0) {
                        this.srVisible = true
                        let tk = {
                            userId: this.userId,
                            date: dateStr,
                            status: true,
                        }
                        setSrtk(tk)
                    }
                } else {
                    if (it.userId != this.userId || it.date != dateStr) {
                        await this.getBirthdayMembers()
                        if (this.list.length > 0) {
                            this.srVisible = true
                            let tk = {
                                userId: this.userId,
                                date: dateStr,
                                status: true,
                            }
                            setSrtk(tk)
                        }
                    }
                }
            }
        },
        methods: {
            async getDept() {
                let res = await this.$get("/sw/getSwDeptInfo", {token: getToken()})
                this.dept = res.dept
                this.userId = res.userId
            },
            async getBirthdayMembers(page) {
                let currentPage = page || 1;
                let data = {
                    token: getToken(),
                    dateType: "1",
                    currentPage
                }
                this.loading = true
                let res = await this.$get("/sw/getBirthdayMembers", data)
                this.loading = false
               this.list  = res.list;
            },
            closeEvent() {
                this.srVisible = false
            },
            toNotice() {
                this.setPopover()
                this.$router.push('/notices/notice')
            },
            toPopover(val) {
                this.visible = val
            },
            setPopover() {
                this.visible = !this.visible
                this.$refs.sidebar.setPopover(this.visible)
            },
            loginOut() {
                removeToken()
                removeSrtk()
                this.$router.push('/login')
            }

        }
    }
</script>

<style>
    .layout-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
    }

    .name-left {
        height: 100%;
        min-height: 500px;
        overflow: auto;
    }

    .name-right {
        height: 100%;
        min-height: 500px;
        overflow: hidden;
    }

    .popover {
        margin-top: 5px;
        margin-left: 5px;
    }

    .neirong {

    }

    .md-info {
        display: flex;
        flex-direction: column;
        border-bottom: #dddddd solid 1px;
        padding: 8px;
    }

    .md-info span {
        height: 22px;
        line-height: 22px;
    }

    .md-tongzhi {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: #dddddd solid 1px;
        padding: 8px;
        cursor: pointer;
    }

    .md-loginout {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
    }

    .loginoutbtn {
        cursor: pointer;
    }

    .mb-img {
        width: 45px;
        height: 45px;
        float: left;
        border-radius: 30px;
        border: #E6E6E6 solid 1px
    }
</style>
